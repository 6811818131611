.footer {
  background-color: black;
  padding: 3vmax;
  display: grid;
  grid-template-columns: 1fr 150px;
  overflow-x: hidden;
}

.footer > div > h5 {
  color: white;
  border-bottom: 1px solid white;
  width: 120px;
  margin-bottom: 20px;
  padding: 10px 0;
}

.footer > div > p {
  color: white;
  width: 60%;
}

.footer > div > h6 {
  color: white;
  padding: 10px 0;
}

.footer > .media {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.footer > .media > .icons > a {
  font-size: 3rem;
  color: white;
  transition: all 0.3s;
  display: block;
}

.footer > .media > .icons {
  display: flex;
  gap: 20px;
}

.media > .icons > a:nth-child(1):hover {
  color: royalblue;
}

.media > .icons > a:nth-child(2):hover {
  color: rgb(204, 71, 71);
}

@media only screen and (max-width: 560px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
  }

  .footer > .footerContact {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 10px;
    width: 80%;
  }

  .footer > .footerContact > h5 {
    margin-bottom: 10px;
  }

  .footer > .footerContact > p {
    display: inline-block;
    width: 100%;
  }

  #contact > .media {
    width: 80%;
  }
}

@media only screen and (max-width: 730px) {
  .footer {
    padding-bottom: 120px;
  }
}
