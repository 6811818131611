.homeCanvas {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  position: absolute;
  background-color: black;
}

.homeCanvasContainer {
  background-color: rgba(0, 0, 0, 0.048);
  /* background-color: black; */
  width: 100%;
  z-index: 2;
  position: relative;
  height: 100vh;
  color: white;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homeCanvasContainer > h1 p {
  transition: all 0.5s;
  color: white;
  display: inline-block;
  letter-spacing: 10px;
}

.homeCanvasContainer > h1 p:hover {
  transform: translateY(-20px);
}

.homeCanvasContainer > a:hover {
  color: rgb(255, 255, 255);
  background-color: rgba(0, 0, 0, 0);
}

.homeCanvasContainer > h1 {
  color: white;
  transform: translate(0%, -80%);
  text-align: center;
  font: 700 4.5vmax "Josefin Sans", sans-serif;

  display: flex;
  gap: 40px;
}

.homeCanvasContainer > h5 {
  transform: translate(0%, -80%);
}

.homeScrollBtn {
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  border-radius: 100%;
  transform: translate(-50%, -25vh);
  padding: 0.5vmax;
  animation: scrollPop 0.5s ease-in infinite alternate;
}

.homeScrollBtn > svg {
  font-size: calc(20px + 1.5vmax);
  color: rgb(255, 255, 255);
}

.homeAbout {
  box-sizing: border-box;
  /* background-color: white; */
  background-color: rgb(255, 255, 241);
  padding-top: calc(30px + 1vmax);
  padding-bottom: calc(40px + 3vmax);
  /* padding-bottom: 200px; */
  /* border-bottom: 200px solid white; */
  position: relative;
  z-index: 1;

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: calc(20px + 0.5vmax);
}

.homeAbout > .homeAboutText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 600px;
}

.homeAbout > .homeAboutText > p {
  color: #343352;
}

.homeAbout > .homeAboutText > h4 {
  color: black;
  padding: 1vmax;
  font: 600 3vmax "Josefin Sans", sans-serif;
}

.homeAbout > .homeAboutAvatar {
  width: 70%;
  max-width: 400px;
  border-radius: 20px;
}

.homeSkills {
  min-height: 100vh;
  height: fit-content;
  /* background-color: #a09ebb; */
  /* background-color: #d9bbf9; */
  /* background-color: #288fddb9; */
  /* background-color: #153243; */
  background-color: #0b111e;
  position: relative;
  box-sizing: border-box;
  /* perspective: 450px; */

  padding-top: 4vmax;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.homeSkills > .homeSkillsText > h4 {
  color: white;
  padding: 4vmax;
  text-align: center;
  /* margin-top: 2vmax; */
  font: 600 3vmax "Josefin Sans", sans-serif;
}

.homeSkills > .homeSkillsBottom > p {
  color: white;
  text-align: center;
  padding-top: 4vmax;
  padding-bottom: 5vmax;
  font: 600 1rem "Josefin Sans", sans-serif;
}

.cubeContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 4vmax 0;
  width: 80%;
  margin: auto;
}

.homeProject {
  min-height: 100vh;
  height: fit-content;
  background-color: lightgray;
  padding-top: 4vmax;
  padding-bottom: 4vmax;
  position: relative;
  box-sizing: border-box;
}

.homeProject > .homeProjectText > h4 {
  text-align: center;
  color: black;
  padding: 4vmax;
  /* margin-top: 2vmax; */
  font: 600 3vmax "Josefin Sans", sans-serif;
}

.homeProjectWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 2vmax;
  width: 80%;
  margin: auto;
}

@keyframes cubeRotation {
  to {
    transform: rotateY(360deg) rotateX(480deg) rotateZ(720deg);
  }
}

@keyframes scrollPop {
  to {
    transform: translate(-50%, -22vh);
  }
}
