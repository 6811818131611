@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

.stuffCard {
  background-color: #fffff2;
  width: 300px;
  transition: all 0.5s;
  padding: calc(15px + 0.3vmax);
  margin: 2vmax;
  border-radius: 20px;
  border: 3px solid #fffff2;
  display: flex;
  flex-direction: column;
}

.stuffCard > a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.712);
  width: inherit;
}

.stuffCard img {
  width: inherit;
  border-radius: 10px;
}

.stuffCard .techStack {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
}

.techStack > .tech {
  background-color: rgb(235, 193, 103);
  padding: 1px 7px;
  margin: 5px 10px 5px 0;
  border-radius: 10px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  color: white;
}

.stuffCard h6 {
  color: #091434;
  padding-top: 15px;
  font-weight: bold;
}

.stuffCard p {
  color: #343352;
  padding: 10px 0;
}

.stuffCard:hover {
  transform: scale(1.1);
  box-shadow: 0 0 10px gray;
}

@media screen and (max-width: 500px) {
  .stuffCard {
    width: 250px;
  }
}
