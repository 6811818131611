.homeCubeSkills {
  width: calc(120px + 1.5vmax);
  height: calc(120px + 1.5vmax);
  animation: cubeRotation 15s linear infinite alternate;
  transform-style: preserve-3d;
}

.homeCubeSkillsFaces {
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px #0b111e;
  position: absolute;
  width: inherit;
  height: inherit;
  opacity: 0.8;
}

.homeCubeSkillsFaces > img {
  width: inherit;
  height: inherit;
}

.homeCubeSkillsFace1 {
  transform: translate3d(0, 0, calc(80px + 1vmax));
}

.homeCubeSkillsFace2 {
  transform: rotateY(180deg) translate3d(0, 0, calc(80px + 1vmax));
}

.homeCubeSkillsFace3 {
  transform: rotateY(-90deg) translate3d(0, 0, calc(80px + 1vmax));
}

.homeCubeSkillsFace4 {
  transform: rotateY(90deg) translate3d(0, 0, calc(80px + 1vmax));
}

.homeCubeSkillsFace5 {
  transform: rotateX(90deg) translate3d(0, 0, calc(80px + 1vmax));
}

.homeCubeSkillsFace6 {
  transform: rotateX(-90deg) translate3d(0, 0, calc(80px + 1vmax));
}

/* .cubeShadow {
  width: 300px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.171);
  border-radius: 50%;
  animation: cubeShadow 1s ease-in-out infinite alternate;
  filter: blur(3px);
} */

.homeCubeInstance {
  min-width: 300px;
  width: 30vmax;
  max-width: 370px;
  min-height: 300px;
  height: 30vmax;
  max-height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
